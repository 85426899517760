
function BigSpace({space}) {
    const styles = {
        width: '100%',
        height: `${space}px`,
    }
    return (
        <div style={styles}>

        </div>
    )
}

export default BigSpace
